// import React, { Component } from "react";
// import { Link } from "react-router-dom";

// class NavbarV3 extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       scrolled: false,
//       imageSource: "black.png", // Default image source
//     };
//   }

//   componentDidMount() {
//     window.addEventListener("scroll", this.handleScroll);
//   }

//   componentWillUnmount() {
//     window.removeEventListener("scroll", this.handleScroll);
//   }

//   handleScroll = () => {
//     const scrollPosition = window.scrollY;
//     const isScrolled = scrollPosition > 100;
//     this.setState({ scrolled: isScrolled });


//     const newColor = this.state.scrolled ? "#8fa9d6" : "#535063";
//     const hoverColor = this.state.scrolled ? "#ffffff" : newColor;

//     const handleColorChange = (selector, color) => {
//       const elements = document.querySelectorAll(selector);
//       elements.forEach((element) => {
//         element.style.color = color;
//         element.style.transition = "color 0.3s"; // Add a smooth transition effect
//       });
//     };

//     const handleHoverEffect = (selector, hoverColor) => {
//       const elements = document.querySelectorAll(selector);
//       elements.forEach((element) => {
//         element.removeEventListener("mouseover", this.handleHoverIn);
//         element.removeEventListener("mouseout", this.handleHoverOut);

//         element.addEventListener("mouseover", () => {
//           element.style.color = hoverColor;
//           element.style.textDecoration = this.state.scrolled
//             ? "none"
//             : "underline";
//         });

//         element.addEventListener("mouseout", () => {
//           element.style.color = newColor;
//           element.style.textDecoration = "none";
//         });
//       });
//     };

//     // Change font color based on scroll position
//     handleColorChange(".main-nav__navigation-box a", newColor);
//     handleColorChange(".side-menu__toggler span", newColor);

//     // Add hover effect based on scroll position
//     handleHoverEffect(".main-nav__navigation-box a", hoverColor);
//     handleHoverEffect(".side-menu__toggler span", hoverColor);
//   };

//   render() {
//     let publicUrl = process.env.PUBLIC_URL + "/";
//     let imgattr = "logo";

//     return (
//       <div>
//         <header className="site-header-two site-header-two__home-three go-top">
//           <nav className="main-nav__two stricky">
//             <div className="container">
//               <style>
//                 {`
//                   .main-nav__navigation-box a {
//                     color: ${this.state.scrolled ? "#8fa9d6" : "#535063"};
//                     transition: color 0.3s; /* Add a smooth transition effect */
//                     text-decoration: none; /* Remove underline by default */
//                   }

//                   .main-nav__navigation-box a:hover {
//                     color: ${this.state.scrolled ? "#ffffff" : "#535063"};
//                     text-decoration: ${
//                       this.state.scrolled ? "none" : "underline"
//                     };
//                     transition: color 0.3s, text-decoration 0.3s; /* Add smooth transitions */
//                   }
//                 `}
//               </style>
//               <div className="main-nav__logo-box">
//                 <Link to="/">
//                   <img
//                     id="customNavImg"
//                     className="logo"
//                     src={publicUrl + "assets/images/" + this.state.imageSource}
//                     alt={imgattr}
//                   />
//                 </Link>
//               </div>
//               {/* /.main-nav__logo-box */}
//               <div className="main-nav__main-navigation">
//                 <ul className="main-nav__navigation-box">
//                   <li>
//                     <Link to="/services">Services</Link>
//                   </li>
//                   <li>
//                     <Link to="/competency">Competency</Link>
//                   </li>
//                   <li>
//                     <Link to="/careers">Careers</Link>
//                   </li>

//                   <li>
//                     <Link to="/Utilities">Utilities</Link>
//                     {/* <ul>
//                       <li>
//                         <Link to="/utilities">News Page</Link>
//                       </li>
//                       <li>
//                         <Link to="/blog-list">News List</Link>
//                       </li>
//                       <li>
//                         <Link to="/blog-details">News Details</Link>
//                       </li>
//                     </ul> */}
//                   </li>
//                   <li>
//                     <Link to="/contact">Contact</Link>
//                   </li>
//                 </ul>
//               </div>
//               {/* /.main-nav__main-navigation */}
//               <div className="main-nav__right">
//                 <Link to="/navbar" className="side-menu__toggler">
//                   <span />
//                 </Link>
//               </div>
//               {/* /.main-nav__right */}
//             </div>
//             {/* /.container */}
//           </nav>
//           {/* /.main-nav__one */}
//         </header>
//       </div>
//     );
//   }
// }

// export default NavbarV3;





















import React, { Component } from "react";
import { Link } from "react-router-dom";

class NavbarV3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: false,
      imageSource: "black.png",
      dropdownOpen: false,
      mobileMenuOpen: false,
    };
    this.dropdownRef = React.createRef();
    this.mobileMenuRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleScroll = () => {
    const scrollPosition = window.scrollY;
    const isScrolled = scrollPosition > 100;
    this.setState({ scrolled: isScrolled });

    const newColor = isScrolled ? "#8fa9d6" : "#535063";
    const hoverColor = isScrolled ? "#ffffff" : newColor;

    this.handleColorChange(".main-nav__navigation-box a", newColor);
    this.handleColorChange(".side-menu__toggler span", newColor);
    this.handleHoverEffect(".main-nav__navigation-box a", hoverColor, newColor);
    this.handleHoverEffect(".side-menu__toggler span", hoverColor, newColor);
  };

  handleColorChange = (selector, color) => {
    const elements = document.querySelectorAll(selector);
    elements.forEach((element) => {
      element.style.color = color;
      element.style.transition = "color 0.3s";
    });
  };

  handleHoverEffect = (selector, hoverColor, defaultColor) => {
    const elements = document.querySelectorAll(selector);
    elements.forEach((element) => {
      element.addEventListener("mouseover", () => {
        element.style.color = hoverColor;
        element.style.textDecoration = this.state.scrolled ? "none" : "underline";
      });

      element.addEventListener("mouseout", () => {
        element.style.color = defaultColor;
        element.style.textDecoration = "none";
      });
    });
  };

  toggleDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  toggleMobileMenu = (e) => {
    e.stopPropagation();
    this.setState((prevState) => ({
      mobileMenuOpen: !prevState.mobileMenuOpen,
      dropdownOpen: false,
    }));
  };

  handleClickOutside = (event) => {
    if (
      this.dropdownRef.current &&
      !this.dropdownRef.current.contains(event.target) &&
      this.state.dropdownOpen
    ) {
      this.setState({ dropdownOpen: false });
    }
    if (
      this.mobileMenuRef.current &&
      !this.mobileMenuRef.current.contains(event.target) &&
      // !event.target.classList.contains('mobile-menu-toggle') &&
      this.state.mobileMenuOpen
    ) {
      this.setState({ mobileMenuOpen: false });
    }
  };

  render() {
    const { scrolled, imageSource, dropdownOpen, mobileMenuOpen } = this.state;
    const publicUrl = process.env.PUBLIC_URL + "/";
    const imgattr = "logo";

    return (
      <header className={`site-header-two site-header-two__home-three go-top ${scrolled ? "scrolled" : ""}`}>
        <nav className="main-nav__two stricky">
          <div className="container">
            <style>
              {`
                .main-nav__two {
                  padding: 10px 0;
                  transition: all 0.3s ease;
                }
                .scrolled .main-nav__two {
                  background-color: #ffffff;
                  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
                }
                .main-nav__navigation-box {
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                }
                .main-nav__navigation-box a {
                  color: ${scrolled ? "#8fa9d6" : "#535063"};
                  transition: color 0.3s, text-decoration 0.3s;
                  text-decoration: none;
                  padding: 10px 15px;
                  display: block;
                }
                .main-nav__navigation-box a:hover {
                  color: ${scrolled ? "#ffffff" : "#535063"};
                  text-decoration: ${scrolled ? "none" : "underline"};
                }
                .dropdown {
                  position: relative;
                }
                .dropdown > a::after {
                  content: '▼';
                  font-size: 0.7em;
                  margin-left: 5px;
                  vertical-align: middle;
                }
                .dropdown-menu {
                  display: none;
                  position: absolute;
                  background-color: white;
                  min-width: 160px;
                  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
                  border-radius: 4px;
                  padding: 8px 0;
                  z-index: 1000;
                  top: 100%;
                  left: 0;
                  margin-top: 0;
                  opacity: 0;
                  visibility: hidden;
                  transition: opacity 0.2s ease, visibility 0.2s ease;
                }
                .dropdown-menu.show {
                  display: block;
                  opacity: 1;
                  visibility: visible;
                }
                .dropdown-menu a {
                  color: #535063;
                  padding: 8px 16px;
                  display: block;
                  white-space: nowrap;
                  transition: background-color 0.2s ease;
                }
                .dropdown-menu a:hover {
                  background-color: #f8f9fa;
                }
                // .mobile-menu-toggle {
                //   display: none;
                //   background: none;
                //   border: none;
                //   font-size: 24px;
                //   cursor: pointer;
                //   padding: 10px;
                //   transition: opacity 0.2s ease;
                // }
                // .mobile-menu-toggle:hover {
                //   opacity: 0.7;
                // }
                @media (max-width: 991px) {
                  // .mobile-menu-toggle {
                  //   display: block;
                  // }
                  .main-nav__main-navigation {
                    display: none;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    right: 0;
                    background-color: white;
                    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
                  }
                  .main-nav__main-navigation.show {
                    display: block;
                  }
                  .main-nav__navigation-box {
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 10px 0;
                  }
                  .dropdown-menu {
                    position: static;
                    box-shadow: none;
                    padding-left: 20px;
                    opacity: 1;
                    visibility: visible;
                  }
                  .dropdown > a::after {
                    display: none;
                  }
                }
              `}
            </style>
            <div className="main-nav__logo-box">
              <Link to="/">
                <img
                  id="customNavImg"
                  className="logo"
                  src={publicUrl + "assets/images/" + imageSource}
                  alt={imgattr}
                />
              </Link>
            </div>
            {/* <button 
              className="mobile-menu-toggle" 
              onClick={this.toggleMobileMenu}
              aria-expanded={mobileMenuOpen}
              aria-label="Toggle navigation menu"
            >
              ☰
            </button> */}
            <div className={`main-nav__main-navigation ${mobileMenuOpen ? 'show' : ''}`} ref={this.mobileMenuRef}>
              <ul className="main-nav__navigation-box">
                <li>
                  <Link to="/services">Services</Link>
                </li>
                <li>
                  <Link to="/competency">Competency</Link>
                </li>
                <li>
                  <Link to="/careers">Careers</Link>
                </li>
                <li className="dropdown" ref={this.dropdownRef}>
                <a 
                  href="#" 
                  onClick={this.toggleDropdown}
                  aria-expanded={dropdownOpen}
                  aria-haspopup="true"
                >
                  Products
                </a>
                  <ul className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`} role="menu">
                    <li>
                      <a 
                        href="https://sme.aasmatech.com/" 
                        target="_blank" 
                        rel="noopener noreferrer"
                      >
                        Slab Estimate
                      </a>
                    </li>
                    <li>
                      <Link to="/commuNet">CommuNet</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
            <div className="main-nav__right">
              <Link to="#" className="side-menu__toggler">
                <span />
              </Link>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default NavbarV3;

