import React from "react";

const CaseStudies = () => {
  const googleDrivePDFUrl =
    "https://drive.google.com/file/d/1pMbIwRxlxlPDiX8eZPtXffIxdlkZZjQ3/preview";

  return (
    <div style={{ position: "relative", width: "100%", height: "100vh" }}>
      <iframe
        src={googleDrivePDFUrl}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          border: "none",
        }}
        title="Case Studies PDF"
      ></iframe>
    </div>
  );
};

export default CaseStudies;

// import React from "react";

// const CaseStudies = () => {
//   const googleDrivePDFUrl =
//     "https://drive.google.com/file/d/1pMbIwRxlxlPDiX8eZPtXffIxdlkZZjQ3/preview";

//   const openInNewTab = () => {
//     window.open(googleDrivePDFUrl, "_blank", "noopener,noreferrer");
//   };

//   return (
//     <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
//       <button
//         onClick={openInNewTab}
//         style={{
//           padding: "10px 20px",
//           fontSize: "16px",
//           backgroundColor: "#007BFF",
//           color: "#fff",
//           border: "none",
//           borderRadius: "5px",
//           cursor: "pointer",
//         }}
//       >
//         Open Case Studies PDF
//       </button>
//     </div>
//   );
// };

// export default CaseStudies;









// import React from "react";
// import { Worker, Viewer } from "@react-pdf-viewer/core";
// import "@react-pdf-viewer/core/lib/styles/index.css";

// const CaseStudies = () => {
//   const googleDrivePDFUrl =
//     "https://drive.google.com/uc?export=download&id=1f5hmVsGxpOGrDHw4h63y5ENVzBbA44uo";

//   return (
//     <div style={{ height: "100vh", width: "100%" }}>
//       <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
//         <Viewer fileUrl={googleDrivePDFUrl} />
//       </Worker>
//     </div>
//   );
// };

// export default CaseStudies;

