import React, { useState } from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  const [activeSection, setActiveSection] = useState(null);

  const policies = [
    {
      title: "Introduction and Consent",
      content: "BY USING THIS WEBSITE, YOU CONSENT TO THE COLLECTION AND USE OF INFORMATION AS SET FORTH IN THIS PRIVACY POLICY. This Privacy Policy explains the ways in which we collect, use, process, store, disclose and protect the information of visitors. We aim to ensure that your personal data is secure, accurate, and up to date."
    },
    {
      title: "Scope of Policy",
      content: "In this Privacy Policy the term Website includes the entire contents of this website including but not limited to all information, User Application, software and software as a service offerings, text, displays, images, video and audio, design, selection Web pages, data, messages, text, images, photographs, graphics, audio and video and any other information displayed on the website."
    },
    {
      title: "Collection of Personal Information",
      content: "We collect personally identifiable information which you choose to provide voluntarily. In general, you may browse our website without providing any personally identifiable information about yourself."
    },
    {
      title: "Use and Sharing of Personal Information",
      content: "We use personally identifiable information / data to respond to your inquiry, process an order or allow you to access specific account information to provide you with products or services, to market products and services which we think may be of interest to you. We may also provide the information to trusted entities who work on behalf of or with Aasma Technology Solutions under strict confidentiality agreements. However, these companies do not have any independent right to further share or disseminate this information."
    },
    {
      title: "Legal Compliance and Safety",
      content: "We believe it is necessary to share the information in order to investigate, prevent, or take action against any illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, or as otherwise required by law. We believe it is necessary to share the information in order to protect or enforce Aasma Technology Solutions's rights, usage terms, intellectual or physical property or for the safety of Aasma Technology Solutions or associated parties."
    },
    {
      title: "Cookies and Tracking Technologies",
      content: "Cookies are small pieces of data which are sent by a web site to your web browser and are stored on your computer. We may collect and process information about your visit to this website, such as the pages you visit, the website you came from and some of the searches you perform. You may set most browsers to notify you if you receive a cookie, or you may choose to block cookies with your browser. Our website uses cookies."
    },
    {
      title: "User Responsibility for Information Authenticity",
      content: "Aasma Technology Solutions shall not be responsible for the authenticity of the Personal Data supplied to it and it is the duty of the user to provide the authentic information."
    },
    {
      title: "Data Security Measures",
      content: "Aasma Technology Solutions utilizes appropriate physical, technical and administrative procedures to safeguard the information we collect. Aasma Technology Solutions takes reasonable steps to ensure that all dealings in personally identifiable information is processed fairly & lawfully after receiving consent of the information provider. Aasma Technology Solutions may retain your personally identifiable information as long as there is a business requirement, or if otherwise required under applicable laws."
    },
    {
      title: "Age Restrictions",
      content: "Our services are not intended for children under the age of 13 years. Children below the age of thirteen years should not access our website unless supervised by an adult."
    },
    {
      title: "Opt-Out and Consent Withdrawal",
      content: "Aasma Technology Solutions respects your privacy considerations and hence provides an option to you, to not provide the data or information sought to be collected. Further, you can also withdraw your consent which was earlier given to Aasma Technology Solutions, and the same must be communicated to Aasma Technology Solutions in writing."
    },
    {
      title: "Policy Updates",
      content: "Please note that we reserve the right to change or otherwise update this Privacy Policy at any time. We will post any Policy changes on this page."
    },
    {
      title: "Grievance Redressal Procedure",
      content: "Any grievance or complaint, in relation to processing of information, should be sent to Aasma Technology Solutions in writing to the following contact email ID. E-mail: contact@aasmatech.com"
    }
  ];

  const AccordionSection = ({ title, content, isActive, onClick }) => (
    <div className="accordion-section">
      <button 
        className={`accordion-button ${isActive ? 'active' : ''}`}
        onClick={onClick}
      >
        <span>{title}</span>
        <span className={`arrow ${isActive ? 'active' : ''}`}>▼</span>
      </button>
      {isActive && (
        <div className="accordion-content">
          <p>{content}</p>
        </div>
      )}
    </div>
  );

  return (
    <div className="privacy-policy-container">

      <main className="main-content">
        
        <div className="policy-container">
          <div className="accordion">
            {policies.map((policy, index) => (
              <AccordionSection
                key={index}
                title={policy.title}
                content={policy.content}
                isActive={activeSection === index}
                onClick={() => setActiveSection(activeSection === index ? null : index)}
              />
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default PrivacyPolicy;