import React, { useState, useEffect } from 'react';
import Navbar from '../global-components/navbar-v2';
import Page_header from '../global-components/page-header';
import Footer_v2 from '../global-components/footer';
const CommunetLandingPage = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [
    `/images/image1.jpeg`,
    `/images/image2.jpeg`,
    `/images/image3.jpeg`,
    `/images/image4.jpeg`
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Navbar/>
      <Page_header headertitle="CommuNet"/>
      <div className="communet-landing">
        <style jsx>{`
          @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

          :root {
            --primary-color: #0095f6;
            --secondary-color: #00376b;
            --background-color: #fafafa;
            --text-color: #262626;
            --light-text-color: #8e8e8e;
            --white: #ffffff;
            --phone-color: #1c1c1e;
          }

          * {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
          }

          body {
            font-family: 'Inter', sans-serif;
            background-color: var(--background-color);
            color: var(--text-color);
            line-height: 1.6;
          }

          .communet-landing {
            max-width: 1200px;
            margin: 0 auto;
            padding: 40px 20px;
          }

          .hero {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 60px;
            flex-wrap: wrap;
          }

          .hero-content {
            flex: 1 1 300px;
            padding-right: 40px;
            margin-bottom: 40px;
          }

          h1 {
            font-size: 48px;
            font-weight: 700;
            margin-bottom: 20px;
            color: var(--secondary-color);
          }

          .hero p { 
            font-size: 25px;
            margin-bottom: 30px;
            color: var(--light-text-color);
          }

.phone-container {
  flex: 0 1 300px;
  width: 300px;
  height: 650px; /* Set a fixed height for proper mobile proportions */
  background-color: var(--phone-color);
  border-radius: 40px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  padding: 10px;
  margin: 0 auto;
}

          .phone-frame {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 2px solid #424242;
            border-radius: 40px;
            pointer-events: none;
            z-index: 2;
          }

.phone-screen {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 30px;
  background-color: var(--white);
}

/* Ensure the notch is properly positioned */
.phone-notch {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 150px;
  height: 25px;
  background-color: var(--phone-color);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  z-index: 3;
}

          .phone-button {
            position: absolute;
            right: -2px;
            top: 100px;
            width: 3px;
            height: 30px;
            background-color: #424242;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
          }

.phone-screen img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

          .phone-screen img.active {
            opacity: 1;
          }

          .features {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: 30px;
            margin-bottom: 60px;
          }

          .feature {
            background-color: var(--white);
            padding: 30px;
            border-radius: 10px;
            text-align: center;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
            transition: transform 0.3s ease;
          }

          .feature:hover {
            transform: translateY(-5px);
          }

          .feature i {
            font-size: 36px;
            color: var(--primary-color);
            margin-bottom: 15px;
          }

          .feature h3 {
            font-size: 20px;
            margin-bottom: 10px;
            color: var(--secondary-color);
          }

          .feature p {
            font-size: 14px;
            color: var(--light-text-color);
          }

          .description {
            background-color: var(--white);
            padding: 40px;
            border-radius: 10px;
            margin-bottom: 60px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
          }

          .description h2 {
            font-size: 28px;
            margin-bottom: 20px;
            color: var(--secondary-color);
          }

          .description p {
            font-size: 16px;
            color: var(--light-text-color);
            margin-bottom: 15px;
          }

          .description ul {
            list-style-type: none;
            padding-left: 20px;
          }

          .description li {
            font-size: 16px;
            color: var(--light-text-color);
            margin-bottom: 10px;
            position: relative;
          }

          .description li::before {
            content: "•";
            color: var(--primary-color);
            font-weight: bold;
            position: absolute;
            left: -20px;
          }

          footer {
            background-color: var(--white);
            padding: 40px 0;
            text-align: center;
            border-radius: 10px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
          }

          .download-buttons {
            display: flex;
            justify-content: center;
            gap: 20px;
            margin-bottom: 20px;
          }

          .button {
            display: inline-flex;
            align-items: center;
            padding: 12px 24px;
            background-color: var(--primary-color);
            color: var(--white);
            text-decoration: none;
            border-radius: 5px;
            font-weight: 500;
            transition: background-color 0.3s ease, transform 0.3s ease;
          }

          .button:hover {
            background-color: var(--secondary-color);
            transform: translateY(-2px);
          }

          .button i {
            margin-right: 8px;
          }

          footer p {
            font-size: 14px;
            color: var(--light-text-color);
          }
@media (max-width: 768px) {
  .phone-container {
    width: 280px; /* Fixed width for mobile */
    height: 600px; /* Fixed height for mobile */
    max-width: none;
    aspect-ratio: auto; /* Remove aspect-ratio to use fixed dimensions */
  }
}

  .hero-content {
    padding-right: 0;
    margin-bottom: 40px;
  }

  .phone-container {
    max-width: 80%;
    width: 80%;
    aspect-ratio: 19.5 / 42;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .phone-container {
    width: 280px; /* Fixed width for tablet */
    height: 600px; /* Fixed height for tablet */
    max-width: none;
    aspect-ratio: auto;
  }
}


@media (min-width: 1025px) {
  .phone-container {
    max-width: 300px;
    width: 300px;
  }
}


.hero .highlight {
  font-weight: 500; /* Slightly bolder for "Fusion of" */
  color: #003366; /* Matches the text color */
}

.hero .highlight-bold {
  font-size: 3.2rem; /* Larger font size for emphasis */
  font-weight: 700; /* Bold for "Creativity and Expertise" */
  color: #002244; /* Slightly darker text color for emphasis */
}
        `}</style>

        <div className="hero">
        <div className="hero-content text-center mx-auto px-4 sm:px-6 lg:px-8">
        {/* <span className="highlight">Fusion of</span> <br /> */}
  <p className=" font-medium leading-snug max-w-xl mx-auto">
        <span className="highlight-bold">Stay Connected, Build Together</span>
        <br/>
  
  Discover the ultimate platform to engage, share, and grow with your community. CommuNet bridges the gap between individuals, families, and local businesses, fostering meaningful connections and seamless communication.
  </p>
</div>

          <div className="phone-container">
            <div className="phone-frame"></div>
            <div className="phone-notch"></div>
            <div className="phone-button"></div>
            <div className="phone-screen">
              {images.map((img, index) => (
                <img
                  key={index}
                  src={img}
                  alt={`App Screenshot ${index + 1}`}
                  className={index === currentImage ? 'active' : ''}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="features">
          <div className="feature">
            <i className="fas fa-users"></i>
            <h3>Community & Family</h3>
            <p>Connect with your local community and family members</p>
          </div>
          <div className="feature">
            <i className="fas fa-store"></i>
            <h3>Business Directory</h3>
            <p>Discover and support local businesses</p>
          </div>
          <div className="feature">
            <i className="fas fa-calendar-alt"></i>
            <h3>Events & Posts</h3>
            <p>Stay updated with local events and community posts</p>
          </div>
          <div className="feature">
            <i className="fas fa-newspaper"></i>
            <h3>Magazines & Announcements</h3>
            <p>Access community magazines and important announcements</p>
          </div>
        </div>

        <div className="description">
          <h2>About CommuNet</h2>
          <p>CommuNet is a comprehensive community engagement platform designed to bring people together. Our app provides a space for sharing, connecting, and growing within your community.</p>
          <p>Key features include:</p>
          <ul>
            <li>Secure login with OTP verification</li>
            <li>Community events calendar and registration</li>
            <li>Matrimonial profiles and biodata sharing</li>
            <li>Family member profiles and digital identity cards</li>
            <li>Local business directory and promotion</li>
            <li>Community posts and publications</li>
            <li>Important announcements and notifications</li>
            <li>Multi-language support (English, Hindi, Gujarati)</li>
          </ul>
          <p>Join CommuNet today and experience the power of a connected community!</p>
        </div>

        <footer>
          <div className="download-buttons">
            <a href="https://apps.apple.com/app/communet-svm-amd/id6504842478" className="button">
              <i className="fab fa-apple"></i> App Store
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.aasmatech.communetsvmamd" className="button">
              <i className="fab fa-google-play"></i> Google Play
            </a>
          </div>
          
        </footer>
      </div>
      <Footer_v2/>
    </>
  );
};

export default CommunetLandingPage;

