import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import Privacy_Policy from './section-components/privatepolicy';
import Footer from './global-components/footer';

const PrivacyPolicy = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Privacy Policy"  />
        <Privacy_Policy />
        <Footer />
    </div>
}

export default PrivacyPolicy

